import { Button } from '@mzc-pdc/ui';
import React from 'react';
import { DialogButtonProps } from './infrastructures/DialogButtonProps';

export const ExecuteDangerDialogButton = (props: DialogButtonProps) => {
  return (
    <Button
      disabled={props.disabled}
      onClick={props.onClick}
      color={'error'}
      variant={'contained'}
    >
      {props.children}
    </Button>
  );
};
