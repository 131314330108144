import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import useRouting from '@hook/useRouting';
import { ExceptionHandler } from '@hook/exceptionHandlers/exceptionHandler';

export const useSpaceQueryExceptionHandler = (): ExceptionHandler => {
  const routing = useRouting();
  return useCallback((error: unknown) => {
    if (!(error instanceof AxiosError)) return true;
    if (error?.response?.status === 401) routing.navigate(routing.welcome.getPath());

    if (error?.response?.status === 403 || error?.response?.status === 409) {
      toast(error.message, { type: 'error' });
    }
    return false;
  }, []);
};
