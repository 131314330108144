import React from 'react';
import useRouting from '@hook/useRouting';
import { useAuthorizationCookie } from '@hook/useAuthorizationCookie';

const AuthProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const authorizationCookie = useAuthorizationCookie();
  const token = authorizationCookie.get();
  const routing = useRouting();
  if (token == null) routing.navigate(routing.welcome.getPath());
  return <>{children}</>;
};

export default AuthProtectedRoute;
