import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { popsConfig } from '@config/popsConfig';
import { SpaceId } from '@domain/models/spaceId';
import { config } from '@config/config';

export interface RoutingInfo {
  getPath: (...args: string[]) => string;
  rule: string;
  isNeededAuthorization: boolean;
  isEnabled: boolean;
}

const useRouting = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { spaceId } = useParams();
  const space = useMemo((): SpaceId | null => {
    if (spaceId != null) return { id: spaceId };

    const paths = location.pathname.split('/');
    const parsedSpaceId =
      paths.length > 2 && paths[0] === '' && paths[1] === 'spaces' ? paths[2] : null;
    if (parsedSpaceId == null) return null;
    return { id: parsedSpaceId };
  }, [spaceId, useParams, location]);

  const routingInfos = useMemo(() => {
    const build = (
      getPath: (...args: string[]) => string,
      ids: string[] = [],
      isNeededAuthorization = false,
      isEnabled = true
    ): RoutingInfo => {
      const result = {
        rule: getPath(...ids),
        isNeededAuthorization: isNeededAuthorization,
        isEnabled: isEnabled
      };
      const getEnabledPath = (...args: string[]) => {
        if (isEnabled) {
          return getPath(...args);
        }
        return '/not-found';
      };
      if (space?.id != null && ids.length > 0 && ids[0] === ':spaceId') {
        return {
          ...result,
          getPath: (...args: string[]) => getEnabledPath(space.id, ...args)
        };
      }
      return {
        ...result,
        getPath: getEnabledPath
      };
    };
    return {
      home: build((spaceId: string) => `/spaces/${spaceId}`, [':spaceId'], true),

      welcome: build(() => '/welcome'),
      login: build(() => popsConfig.redirectUrl, [], false, config.environment === 'local'),
      spaces: build(() => `/`, [], true),
      invalidUser: build(() => '/invalid-user'),
      browserNotSupported: build(() => '/error/browser'),

      users: build((spaceId: string) => `/spaces/${spaceId}/users`, [':spaceId'], true),
      user: build(
        (spaceId: string, userId: string) => `/spaces/${spaceId}/users/${userId}`,
        [':spaceId', ':userId'],
        true
      ),

      projects: build((spaceId: string) => `/spaces/${spaceId}/projects`, [':spaceId'], true),
      project: build(
        (spaceId: string, projectId: string) => `/spaces/${spaceId}/projects/${projectId}`,
        [':spaceId', ':projectId'],
        true
      ),
      projectCreateSelection: build(
        (spaceId: string) => `/spaces/${spaceId}/projects/create-selection`,
        [':spaceId'],
        true
      ),
      projectCreate: build(
        (spaceId: string) => `/spaces/${spaceId}/projects/create`,
        [':spaceId'],
        true
      ),
      projectCreateFromRecord: build(
        (spaceId: string) => `/spaces/${spaceId}/projects/create-from-record`,
        [':spaceId'],
        true
      ),
      projectUser: build(
        (spaceId: string, projectId: string, userId: string) =>
          `/spaces/${spaceId}/projects/${projectId}/users/${userId}`,
        [':spaceId', ':projectId', ':userId'],
        true
      ),

      credentials: build((spaceId: string) => `/spaces/${spaceId}/credentials`, [':spaceId'], true),
      credential: build(
        (spaceId: string, credentialId: string) => `/spaces/${spaceId}/credentials/${credentialId}`,
        [':spaceId', ':credentialId'],
        true
      ),
      credentialCreate: build(
        (spaceId: string) => `/spaces/${spaceId}/credentials/create`,
        [':spaceId'],
        true
      ),
      metrics: build((spaceId: string) => `/spaces/${spaceId}/metric-presets`, [':spaceId'], true),
      metric: build(
        (spaceId: string, metricPresetId: string) =>
          `/spaces/${spaceId}/metric-presets/${metricPresetId}`,
        [':spaceId', ':metricPresetId'],
        true
      ),
      createMetricThreshold: build(
        (spaceId: string, metricPresetId: string) =>
          `/spaces/${spaceId}/metric-presets/${metricPresetId}/create-threshold`,
        [':spaceId', ':metricPresetId'],
        true
      ),
      createMetrics: build(
        (spaceId: string) => `/spaces/${spaceId}/metric-presets/create`,
        [':spaceId'],
        true
      ),
      notificationReceivers: build(
        (spaceId: string) => `/spaces/${spaceId}/notification-receivers`,
        [':spaceId'],
        true
      ),
      notificationReceiver: build(
        (spaceId: string, notificationReceiverId: string) =>
          `/spaces/${spaceId}/notification-receivers/${notificationReceiverId}`,
        [':spaceId', ':notificationReceiverId'],
        true
      ),
      createNotificationReceiver: build(
        (spaceId: string) => `/spaces/${spaceId}/notification-receivers/create`,
        [':spaceId'],
        true
      ),

      endpoints: build((spaceId: string) => `/spaces/${spaceId}/endpoints`, [':spaceId'], true),
      dnses: build((spaceId: string) => `/spaces/${spaceId}/dnses`, [':spaceId'], true),
      cdns: build((spaceId: string) => `/spaces/${spaceId}/cdns`, [':spaceId'], true),
      storages: build((spaceId: string) => `/spaces/${spaceId}/storages`, [':spaceId'], true),
      routingPolicies: build(
        (spaceId: string) => `/spaces/${spaceId}/routing-policies`,
        [':spaceId'],
        true
      ),

      dnsZoneRecordJobs: build((spaceId: string) => `/spaces/${spaceId}/record-jobs`, [':spaceId'], true),
      purgeJobs: build((spaceId: string) => `/spaces/${spaceId}/purge-jobs`, [':spaceId'], true),
      compareJobs: build(
        (spaceId: string) => `/spaces/${spaceId}/compare-jobs`,
        [':spaceId'],
        true
      ),

      monitoring: build(
        (spaceId: string) => `/spaces/${spaceId}/cdn-monitoring`,
        [':spaceId'],
        true
      ),

      auditLogs: build((spaceId: string) => `/spaces/${spaceId}/audit-logs`, [':spaceId'], true),
      accessAuditLogs: build((spaceId: string) => `/spaces/${spaceId}/access-audit-logs`, [':spaceId'], true),
    };
  }, [space]);

  return { ...routingInfos, space: space, navigate: navigate };
};

export default useRouting;
