import { Grid, Typography } from '@mzc-pdc/ui';
import React from 'react';
import { ReactNode } from 'react';

interface RequiredLabelProps {
  children: ReactNode;
}

export const RequiredLabel = (props: RequiredLabelProps) => (
  <Grid container flexDirection={'row'}>
    <Grid item>
      <Typography variant={'body1_500'} component={'strong'}>
        {props.children}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant={'body1_500'} component={'span'} color={'error'} ml={0.5}>
        *
      </Typography>
    </Grid>
  </Grid>
);
