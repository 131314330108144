import { Typography } from '@mzc-pdc/ui';
import React from 'react';
import { ReactNode } from 'react';

interface HeaderLabelProps {
  children: ReactNode;
}

export const HeaderLabel = (props: HeaderLabelProps) => (
  <Typography variant={'body1_500'} component={'strong'}>
    {props.children}
  </Typography>
);

export const LightHeaderLabel = (props: HeaderLabelProps) => (
  <Typography variant={'body1_500'} color={'grey'}>
    {props.children}
  </Typography>
);
