import React from 'react';
import { Menu, PopoverProps } from '@mzc-pdc/ui';

interface PopoverMenuProps {
  anchor: PopoverProps['anchorEl'];
  onClose: () => void;
  children: React.ReactNode;
}

export const PopoverMenu = (props: PopoverMenuProps) => (
  <Menu
    anchorEl={props.anchor}
    open={props.anchor != null}
    onClose={props.onClose}
    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    slotProps={{ paper: { sx: { width: '200px' } } }}
  >
    {props.children}
  </Menu>
);
