import React from 'react';
import { Select as PdcSelect } from '@mzc-pdc/ui';
import { FormControl, MenuItem } from '../../index';
import { useTranslation } from 'react-i18next';

export const PagesizeSelect = (props: {
  value: number;
  disabled?: boolean;
  onChange?: (page: number, pageSize: number) => void;
}) => {
  const { t } = useTranslation();
  return (
    <FormControl>
      <PdcSelect
        value={props.value}
        disabled={props?.disabled ?? false}
        onChange={(event) => props.onChange?.(1, event.target.value as number)}
      >
        <MenuItem value={10}>{t('listView', { viewValue: 10 })}</MenuItem>
        <MenuItem value={20}>{t('listView', { viewValue: 20 })}</MenuItem>
        <MenuItem value={30}>{t('listView', { viewValue: 30 })}</MenuItem>
      </PdcSelect>
    </FormControl>
  );
};
