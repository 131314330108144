import useAxios from '@hook/useCases/useAxios';
import { QueryOptions } from '@hook/useCases/infrastructures/queryOptions';
import { useQueryBase } from '@hook/useCases/infrastructures/useQueryBase';
import { useSpaceQueryExceptionHandler } from '@hook/exceptionHandlers/useSpaceQueryExceptionHandler';
import { User } from '@domain/models/user';

export const useGetMyInfo = (options?: QueryOptions) => {
  const { space: client } = useAxios();
  const handleException = useSpaceQueryExceptionHandler();

  return useQueryBase(
    [`/users/me`],
    async (context): Promise<User> => {
      const response = await client.get(`/users/me`, {
        signal: context.signal
      });
      return response?.data;
    },
    options,
    handleException
  );
};

