import React, { ReactNode } from 'react';
import { Alert as PdcAlert } from '@mzc-pdc/ui';
import { IconInfoCircle } from '@mzc-cloudplex/icons';

export const InfoAlert = (props: { children: ReactNode }) => (
  <PdcAlert
    color={'info'}
    severity={'info'}
    icon={<IconInfoCircle fontSize='small' height={20} />}
  >
    {props.children}
  </PdcAlert>
);
