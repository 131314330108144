import React from 'react';
import { Tooltip as PdcTooltip } from '@mzc-pdc/ui';

interface TooltipProps {
  children: React.ReactElement<any, any>;
  title: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  isVisible?: boolean;
}

export const Tooltip = (props: TooltipProps) =>
  props.isVisible === false ? (
    props.children
  ) : (
    <PdcTooltip title={props.title} placement={props.placement}>{props.children}</PdcTooltip>
  );
