import React from 'react';
import { Grid, IconButton, Tooltip } from '@mzc-pdc/ui';
import { IconClose, IconEditSmall, IconSave } from '@mzc-cloudplex/icons';
import { ValidConditionContainer } from './ValidConditionContainer';

export const EditablePropertyContent = ({
  value,
  isEditing,
  isLoading,
  isValid,
  children,
  onClose,
  onEdit,
  onEditStart,
  disabled
}: {
  value: React.ReactNode;
  isEditing: boolean;
  isLoading: boolean;
  isValid: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  onEdit?: () => void;
  onEditStart?: () => void;
  disabled: boolean;
}) => (
  <>
    <ValidConditionContainer condition={isEditing}>
      <Grid container flexDirection={'column'}>
        <Grid item>{children}</Grid>
        <Grid item sx={{ display: `flex`, justifyContent: `flex-end`, gap: `4px`, mt: 0.5 }}>
          <IconButton color={`inherit`} size={`medium`} variant={'outlined'} onClick={onClose}>
            <IconClose size={16} />
          </IconButton>
          <IconButton
            color={`primary`}
            size={`medium`}
            variant={'outlined'}
            loading={isLoading}
            disabled={!isValid || isLoading}
            onClick={onEdit}
          >
            <IconSave size={16} />
          </IconButton>
        </Grid>
      </Grid>
    </ValidConditionContainer>
    <ValidConditionContainer condition={!isEditing}>
      <Grid container flexDirection={'row'}>
        <Grid item xs={11}>
          {value}
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={`Edit`}>
            <span>
              <IconButton
                className={'btn-edit'}
                size={'xsmall'}
                variant={'dashed'}
                circled
                sx={{ ml: 1 }}
                disabled={disabled}
                onClick={onEditStart}
              >
                <IconEditSmall size={12} />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </ValidConditionContainer>
  </>
);
