import React from 'react';
import { IconButtonProps } from './infrastructures/IconButtonProps';
import { IconButton } from '@mzc-pdc/ui';
import { IconMoreHoriz } from '@mzc-cloudplex/icons';

export const MoreIconButton = (props: IconButtonProps) => (
  <IconButton circled size={`large`} onClick={props.onClick}>
    <IconMoreHoriz size={16} />
  </IconButton>
);
