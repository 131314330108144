import React from 'react';
import { Button } from '@mzc-pdc/ui';
import { ButtonProps } from './infrastructures/ButtonProps';

export const CancelButton = (props: ButtonProps) => (
  <Button
    variant={'text'}
    color={'secondary'}
    size={'medium'}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
);
