import { IconClose, IconEditSmall, IconSave } from '@mzc-cloudplex/icons';
import { Box, Divider, Grid, IconButton, Tooltip, Typography } from '@mzc-pdc/ui';
import React, { ReactNode } from 'react';

export const OverviewItemGroup = ({ children }: { children: React.ReactNode }) => (
  <Grid item xs={6}>
    <Box display={'flex'} flexDirection={'column'} gap={4}>
      {children}
    </Box>
  </Grid>
);

export const OverviewSeparator = () => (
  <Divider flexItem orientation={'vertical'} sx={{ margin: `0 14px` }} />
);

export const OverviewItem = ({ header, children }: { header: ReactNode; children: ReactNode }) => (
  <Grid container flexDirection={'column'} gap={1}>
    <Grid item>{header}</Grid>
    <Grid item>{children}</Grid>
  </Grid>
);

export const ReadOnlyHeader = ({ title }: { title: string }) => {
  return (
    <Typography variant={'body2'} color={'text.secondary'} mb={1}>
      {title}
    </Typography>
  );
};

export const ReadOnlyContent = ({ value }: { value: React.ReactNode }) => {
  return <Typography variant={'subtitle2'}>{value}</Typography>;
};

export const EditableHeader = ({
  title,
  disabled,
  onClick
}: {
  title: string;
  disabled: boolean;
  onClick?: () => void;
}) => {
  return (
    <Box mb={1} display={'flex'} alignItems={'center'} gap={1}>
      <Typography variant={'body2'} component='span' color={'text.secondary'}>
        {title}
      </Typography>
      <Tooltip title={`Edit`}>
        <IconButton
          className={'btn-edit'}
          size={'xsmall'}
          variant={'dashed'}
          circled
          disabled={disabled}
          onClick={() => onClick?.()}
        >
          <IconEditSmall size={12} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export const EditableContent = ({
  value,
  isEdit,
  isLoading,
  isValid,
  children,
  onClose,
  onEdit
}: {
  value: React.ReactNode;
  isEdit: boolean;
  isLoading: boolean;
  isValid: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  onEdit?: () => void;
}) => {
  if (isEdit) {
    return (
      <>
        {children}
        <Grid item sx={{ display: `flex`, justifyContent: `flex-end`, gap: `4px`, mt: 0.5 }}>
          <IconButton color={`inherit`} size={`medium`} variant={'outlined'} onClick={onClose}>
            <IconClose size={16} />
          </IconButton>
          <IconButton
            color={`primary`}
            size={`medium`}
            variant={'outlined'}
            loading={isLoading}
            disabled={!isValid || isLoading}
            onClick={onEdit}
          >
            <IconSave size={16} />
          </IconButton>
        </Grid>
      </>
    );
  }
  return <>{value}</>;
};
