import { useMemo } from 'react';
import axios from 'axios';
import { deliveryServiceConfig } from '@config/deliveryServerConfig';
import { useAuthorizationCookie } from '@hook/useAuthorizationCookie';

const spaceClient = axios.create({
  baseURL: deliveryServiceConfig.adminApi,
  headers: {
    'Content-Type': 'application/json'
  }
});

const spaceClientV2 = axios.create({
  baseURL: deliveryServiceConfig.adminApiV2,
  headers: {
    'Content-Type': 'application/json'
  }
});

const projectClient = axios.create({
  baseURL: deliveryServiceConfig.projectApi,
  headers: {
    'Content-Type': 'application/json'
  }
});

const projectClientV4 = axios.create({
  baseURL: deliveryServiceConfig.projectApiV4,
  headers: {
    'Content-Type': 'application/json'
  }
});

const useAxios = () => {
  const authorizationCookie = useAuthorizationCookie();
  return useMemo(() => {
    spaceClient.defaults.headers['Authorization'] = authorizationCookie.get();
    spaceClientV2.defaults.headers['Authorization'] = authorizationCookie.get();
    projectClient.defaults.headers['Authorization'] = authorizationCookie.get();
    projectClientV4.defaults.headers['Authorization'] = authorizationCookie.get();

    spaceClient.defaults.paramsSerializer = function (paramObj) {
      const params = new URLSearchParams();
      for (const key in paramObj) {
        if (key != null && paramObj[key] != null) params.append(key, paramObj[key]);
      }

      return params.toString();
    };
    return {
      space: spaceClient,
      spaceV2: spaceClientV2,
      project: projectClient,
      projectV4: projectClientV4
    };
  }, [authorizationCookie]);
};

export default useAxios;
