import React from 'react';
import { ReactNode } from 'react';

interface ErrorContainerProps {
  isError: boolean;
  fallback?: ReactNode;
  children: ReactNode;
}

export const ErrorContainer = ({ isError, fallback, children }: ErrorContainerProps) => {
  if (isError) return <>{fallback}</>;
  return <>{children}</>;
};
