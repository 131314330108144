import { useCallback } from 'react';

export type Unit = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB';

export const useByteConverter = () => {
  const convertByteAndUnit = useCallback((byte: bigint | number) => {
    const split = (value: number) => {
      return Math.round(value * 10000) / 10000;
    };
    const units: Array<Unit> = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    let calculatedByte = Number(byte);
    for (let index = 0; index < units.length; index++) {
      if (calculatedByte < 1024) {
        return {
          byte: split(calculatedByte),
          unit: units[index]
        };
      }
      calculatedByte = calculatedByte / 1024;
    }
    return {
      byte: split(calculatedByte),
      unit: 'EB'
    };
  }, []);
  const convertByte = useCallback((byte: bigint | number, unit: Unit) => {
    let exponent = 0;
    switch (unit) {
      case 'B':
        exponent = 0;
        break;
      case 'KB':
        exponent = 1;
        break;
      case 'MB':
        exponent = 2;
        break;
      case 'GB':
        exponent = 3;
        break;
      case 'TB':
        exponent = 4;
        break;
      case 'PB':
        exponent = 5;
        break;
      case 'EB':
        exponent = 6;
        break;
    }
    return BigInt(Math.round(Number(byte) * Math.pow(1024, exponent)));
  }, []);
  return {
    convertByteAndUnit: convertByteAndUnit,
    convertByte: convertByte
  };
};
