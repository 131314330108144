import React from 'react';
import { ButtonProps } from './infrastructures/ButtonProps';
import { Button } from '@mzc-pdc/ui';

export const InfoButton = (props: ButtonProps) => (
  <Button
    variant={'text'}
    color={'secondary'}
    size={'medium'}
    disabled={props.disabled}
    onClick={props.onClick}
    startIcon={props.startIcon}
    endIcon={props.endIcon}
  >
    {props.children}
  </Button>
);
