import React from 'react';
import { Typography as PdcTypography } from '@mzc-pdc/ui';
import { TypographyProps } from './infrastructures/TypographyProps';
import { theme } from '../../theme';

export const Typography = (props: TypographyProps) => {
  const getColor = () => {
    switch (props.color) {
      case 'grey':
        return theme.palette.grey.main;
      case 'error':
        return theme.palette.error.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'success':
        return theme.palette.success.main;
      case 'primary':
        return theme.palette.primary.main;
      default:
        return undefined;
    }
  };
  return (
    <PdcTypography
      variant={props.bold ? 'body1_500' : 'body1'}
      color={props.color ? getColor() : undefined}
      align={props.centered ? 'center' : undefined}
      style={props.lineBreak ? { wordBreak: 'break-word' } : undefined}
    >
      {props.children}
    </PdcTypography>
  );
};
