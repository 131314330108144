import React from 'react';
import { Skeleton, TableBody, TableCell, TableRow } from '../../index';

export const TableBodySkeleton = (props: {
  rowLength: number;
  colLength: number;
  skeleton?: React.ReactNode;
}) => (
  <TableBody>
    {Array.from({ length: props.rowLength }).map((_, index) => (
      <TableRow key={`row-${index}`}>
        {Array.from({ length: props.colLength }).map((_, index) => (
          <TableCell key={`column-${index}`}>{props.skeleton || <Skeleton />}</TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
);
