import { Typography } from '../typographies/Typography';
import { useByteConverter } from '../../hook/useByteConverter';
import React, { useMemo } from 'react';

export const ByteDisplay = ({
  bytes,
  showPlain = true,
  color
}: {
  bytes: number | bigint;
  showPlain?: boolean;
  color?: 'primary';
}) => {
  const byteConverter = useByteConverter();
  const convertedByte = useMemo(() => byteConverter.convertByteAndUnit(bytes), [bytes]);
  const commaizeNumber = (number: number | bigint | string) =>
    String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (
    <Typography bold color={color}>
      {`${convertedByte.byte.toFixed(2)} ${convertedByte.unit}`}
      {showPlain ? `(${commaizeNumber(bytes)} Byte)` : ''}
    </Typography>
  );
};
