import React from 'react';
import { TextField } from '@mzc-pdc/ui';
import InputFieldProps from './infrastructures/InputFieldProps';

export const InputField = (props: InputFieldProps) => (
  <TextField
    fullWidth
    variant='outlined'
    size={'medium'}
    type={'text'}
    disabled={props.disabled ?? false}
    required={props.required ?? false}
    label={props.label}
    InputProps={{
      startAdornment: props.startAdornment,
      endAdornment: props.endAdornment
    }}
    value={props.value || ''}
    placeholder={props.placeholder ?? ''}
    onChange={(e) => props.onChange(e.target.value)}
    error={props?.errorMessage != null}
    helperText={props?.errorMessage}
  />
);
