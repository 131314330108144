import React, { ReactNode, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppMenuDrawer,
  AppMenuDrawerBody,
  AppMenuDrawerHeader,
  AppMenuList,
  Box,
  Divider,
  IconAlbum,
  IconAnalytics,
  IconArrowBack,
  IconArrowForward,
  IconButton,
  IconCdn,
  IconCompare,
  IconCredential,
  IconDashboard,
  IconDns,
  IconMyLocation,
  IconNotice,
  IconProjects,
  IconPurge,
  IconRoleAdmin,
  IconRoute,
  IconStorage,
  IconUser,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  theme,
  Tooltip,
  TypographySubtitle2,
  ValidConditionContainer
} from '@packages/cp-ui';
import useRouting from '@hook/useRouting';
import { useTranslation } from 'react-i18next';
import { SpaceId } from '@domain/models/spaceId';
import { config } from '@config/config';

const AppMenu = () => {
  const { t } = useTranslation();
  const routing = useRouting();
  const space = routing.space;

  const [isExpanded, setExpanded] = useState(true);

  return (
    <nav>
      <AppMenuDrawer variant='permanent' open={isExpanded}>
        <AppMenuDrawerHeader sx={{ mt: 4, mx: isExpanded ? 3 : 1.5, mb: 2 }}>
          {isExpanded ? (
            <TypographySubtitle2 bold>{t('common::administration')}</TypographySubtitle2>
          ) : (
            <Box display={'flex'}>
              <IconRoleAdmin color={'inherit'} size={16} />
            </Box>
          )}
        </AppMenuDrawerHeader>
        <AppMenuDrawerBody>
          {space != null && <Menu isExpanded={isExpanded} space={space} />}
        </AppMenuDrawerBody>
        <Tooltip title={isExpanded ? 'Folding' : 'Open'} placement={'right'}>
          <IconButton
            circled
            className={'toggle-button'}
            onClick={() => setExpanded((prevState) => !prevState)}
            size={'small'}
            variant={'contained'}
            edge='end'
            color={isExpanded ? 'primary' : 'grey'}
          >
            <ValidConditionContainer condition={isExpanded}>
              <IconArrowBack size={12} htmlColor={`#fff`} />
            </ValidConditionContainer>
            <ValidConditionContainer condition={!isExpanded}>
              <IconArrowForward size={12} htmlColor={`#fff`} />
            </ValidConditionContainer>
          </IconButton>
        </Tooltip>
      </AppMenuDrawer>
    </nav>
  );
};

const Menu = ({ isExpanded, space }: { isExpanded: boolean; space: SpaceId }) => {
  const { t } = useTranslation();
  const routing = useRouting();
  return (
    <AppMenuList>
      <MenuItem
        title={t('dashboard::dashboard')}
        icon={<IconDashboard color={'inherit'} size={16} />}
        link={routing.home.getPath()}
        isExpanded={isExpanded}
        divider
      />
      <Separator isExpanded={isExpanded}>{t('common::management')}</Separator>
      <MenuItem
        title={t('user::user')}
        icon={<IconUser color={'inherit'} size={16} />}
        link={routing.users.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('project::project')}
        icon={<IconProjects color={'inherit'} size={16} />}
        link={routing.projects.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('credential::credential')}
        icon={<IconCredential color={'inherit'} size={16} />}
        link={routing.credentials.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('metrics::metrics')}
        icon={<IconAnalytics color={'inherit'} size={16} />}
        link={routing.metrics.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('notificationReceiver::notificationReceiver')}
        icon={<IconNotice color={'inherit'} size={16} />}
        link={routing.notificationReceivers.getPath()}
        isExpanded={isExpanded}
        divider
      />
      <Separator isExpanded={isExpanded}>{t('common::resource')}</Separator>
      <MenuItem
        title={t('endpoint::endpoint')}
        icon={<IconMyLocation color={'inherit'} size={16} />}
        link={routing.endpoints.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('dns::dns')}
        icon={<IconDns color={'inherit'} size={16} />}
        link={routing.dnses.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('cdn::cdn')}
        icon={<IconCdn color={'inherit'} size={16} />}
        link={routing.cdns.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('routingPolicy::routingPolicy')}
        icon={<IconRoute color={'inherit'} size={16} />}
        link={routing.routingPolicies.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('storage::storage')}
        icon={<IconStorage color={'inherit'} size={16} />}
        link={routing.storages.getPath()}
        isExpanded={isExpanded}
        divider
      />
      <Separator isExpanded={isExpanded}>{t('common::history')}</Separator>
      <MenuItem
        title={t('jobs::dnsZone')}
        icon={<IconAlbum color={'inherit'} size={16} />}
        link={routing.dnsZoneRecordJobs.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('jobs::purge')}
        icon={<IconPurge color={'inherit'} size={16} />}
        link={routing.purgeJobs.getPath()}
        isExpanded={isExpanded}
      />
      <MenuItem
        title={t('jobs::compare')}
        icon={<IconCompare color={'inherit'} size={16} />}
        link={routing.compareJobs.getPath()}
        isExpanded={isExpanded}
      />
      <ValidConditionContainer
        condition={
          (space.id === '5DnGgWbjZeLQoPr6A1vJmYXa' &&
            (config.environment === 'dev' || config.environment === 'local')) ||
          (space.id === '2eAwyp3EJ5KYJP9jox7RaWD6' && config.environment === 'qa') ||
          (space.id === '5DnGgWbjZeLQoPr6A1vJmYXa' && config.environment === 'prod')
        }
      >
        <Separator isExpanded={isExpanded}>{t('auditLog::auditLog')}</Separator>
        <MenuItem
          title={t('auditLog::managementAuditLog')}
          icon={<IconCdn color={'inherit'} size={16} />}
          link={routing.auditLogs.getPath()}
          isExpanded={isExpanded}
        />
        <MenuItem
          title={t('auditLog::accessAuditLog')}
          icon={<IconCdn color={'inherit'} size={16} />}
          link={routing.accessAuditLogs.getPath()}
          isExpanded={isExpanded}
        />
      </ValidConditionContainer>
    </AppMenuList>
  );
};

const MenuItem = ({
  icon,
  title,
  link,
  isExpanded,
  divider
}: {
  icon: React.ReactNode;
  title: string;
  link: string;
  isExpanded: boolean;
  divider?: boolean;
}) => {
  const routing = useRouting();
  const location = useLocation();
  let isMatched: boolean;

  if (link === routing.home.getPath()) isMatched = location.pathname === link;
  else isMatched = location.pathname.includes(link);

  return (
    <ListItem disablePadding divider={divider}>
      <Tooltip title={title} isVisible={!isExpanded} placement={'right'}>
        <Link to={link}>
          <ListItemButton selected={isMatched}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={title} />
          </ListItemButton>
        </Link>
      </Tooltip>
    </ListItem>
  );
};

const Separator = ({ children, isExpanded }: { children: ReactNode; isExpanded: boolean }) => (
  <ListSubheader>
    {isExpanded ? (
      children
    ) : (
      <Divider
        sx={{
          width: 8,
          ml: `14px`,
          borderWidth: 1,
          borderColor: theme.palette.secondary[400]
        }}
      />
    )}
  </ListSubheader>
);

export default AppMenu;
