import { Link, LinkProps } from '../links/Link';
import { Box, Typography } from '@mzc-pdc/ui';
import React, { ReactNode } from 'react';

export const BreadcrumbItem = (
  props: {
    title: string;
    icon?: ReactNode;
  } & LinkProps
) => (
  <Link to={props.to} target={props.target}>
    <Box display={'flex'} flexDirection={'row'} flexWrap={'nowrap'} gap={0.5}>
      {props?.icon}
      <Typography variant={'body3'}>{props.title}</Typography>
    </Box>
  </Link>
);
