import React, { ReactNode } from 'react';
import { Grid, Typography } from '@mzc-pdc/ui';

type FromItemInnerLayoutProps = {
  header?: ReactNode;
  children: ReactNode;
  isRequired?: boolean;
};

export const PropertyContainer = ({ header, children, isRequired }: FromItemInnerLayoutProps) => (
  <Grid container flexWrap={'nowrap'} alignItems={'stretch'}>
    {header && (
      <Grid item xs={2}>
        <Grid container alignItems={'center'} gap={0.5}>
          {header}
          {isRequired && (
            <Typography variant={'body1_500'} component={'span'} color={'error'}>
              *
            </Typography>
          )}
        </Grid>
      </Grid>
    )}
    <Grid item xs={6}>
      {children}
    </Grid>
  </Grid>
);

