import React from 'react';
import { Header as CpHeader } from '@packages/cp-ui';
import useRouting from '@hook/useRouting';
import { useAuthorizationCookie } from '@hook/useAuthorizationCookie';
import { useGetMyInfo } from '@hook/useCases/queries/useGetMyInfo';

export const Header = () => {
  const routing = useRouting();
  const authorizationCookie = useAuthorizationCookie();
  const { data: myInfo } = useGetMyInfo();
  const onLogout = async () => authorizationCookie.remove();
  return <CpHeader homeDirection={routing.spaces.getPath()} myInfo={myInfo!} onLogout={onLogout} />;
};

export default Header;
