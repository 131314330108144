import { Dialog as BaseDialog, DialogActions, DialogContent, DialogTitle } from '@mzc-pdc/ui';
import React, { ReactNode } from 'react';

interface DialogProps {
  title: string;
  open: boolean;
  actions?: ReactNode;
  children: ReactNode;
  onClose?: () => void;
}

export const Dialog = ({ title, open, actions, children, onClose }: DialogProps) => (
  <BaseDialog
    open={open}
    title={title}
    sx={{ '.MuiPaper-root': { minWidth: 600 } }}
    onClose={onClose}
  >
    <DialogTitle>{title}</DialogTitle>
    <div>
      <DialogContent>{children}</DialogContent>
    </div>
    {actions != null && <DialogActions>{actions}</DialogActions>}
  </BaseDialog>
);
