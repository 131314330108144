import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Menu, MenuItem, Typography } from '@mzc-pdc/ui';
import { IconArrowDropDown, IconArrowDropUp, IconLanguage } from '@mzc-cloudplex/icons';
import { theme } from '../../../index';
import { SupportLocaleType, useLocaleProvider } from '../../../hook/useLocaleProvider';

export const Footer = ({
  onLocaleChange
}: {
  onLocaleChange: (locale: SupportLocaleType['value']) => Promise<void>;
}) => {
  const { i18n, t } = useTranslation('common', { keyPrefix: 'footer' });
  const { locales, get } = useLocaleProvider();
  const [currentLanguage, setCurrentLanguage] = useState(locales[0].value);
  const localStorage = window.localStorage;

  const [languageAnchorEl, setLanguageAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) =>
    setLanguageAnchorEl(event.currentTarget);
  const handleClose = () => setLanguageAnchorEl(null);

  const onChangeLanguage = async (lng: SupportLocaleType['value']) => {
    setCurrentLanguage(lng);
    await i18n.changeLanguage(lng);
    await onLocaleChange(lng);
    handleClose();
  };

  useEffect(
    () =>
      setCurrentLanguage(
        (localStorage.getItem('i18nextLang') as SupportLocaleType['value']) || locales[0].value
      ),
    []
  );

  const openTermsPrivacy = useCallback(
    () => window.open(`https://cloudplex.megazone.io/private_policy.html`),
    []
  );

  const openTermsService = useCallback(
    () => window.open(`https://cloudplex.megazone.io/terms_conditions.html`),
    []
  );

  const openCustomerService = useCallback(() => window.open(`https://help.megazone.io/`), []);

  const openRoadmap = useCallback(
    () =>
      window.open(`https://portal.productboard.com/megazone/5-cloudplex-delivery/tabs/21-released`),
    []
  );
  return (
    <footer>
      <Divider sx={{ mx: 5 }} />
      <Grid
        container
        justifyContent={`space-between`}
        alignItems={`center`}
        flexWrap={`nowrap`}
        height={'48px'}
        py={1}
        px={5}
      >
        <Grid item>
          <Grid container direction={`row`} alignItems={`center`} flexWrap={`nowrap`} gap={1.25}>
            <Grid item>
              <Button
                type={`button`}
                size={`small`}
                variant={`text`}
                color={'secondary'}
                onClick={openTermsService}
              >
                {t('termsCondition')}
              </Button>
            </Grid>
            <Divider orientation='vertical' flexItem sx={{ my: 0.75 }} />
            <Grid item>
              <Button
                className={'privacy'}
                type={`button`}
                size={`small`}
                variant={`text`}
                color={'greySecondary'}
                onClick={openTermsPrivacy}
                sx={{ fontWeight: 500, color: theme.palette.greySecondary.A400 }}
              >
                {t('privacyPolicy')}
              </Button>
            </Grid>
            <Divider orientation='vertical' flexItem sx={{ my: 0.75 }} />
            <Grid item className={'address'}>
              <Typography component={'address'} variant={'body3_400'} sx={{ fontStyle: `normal` }}>
                ©{' '}
                <Typography
                  component={`span`}
                  variant={'body3_400'}
                  color={theme.palette.primary.main}
                >
                  MegazoneCloud Corp.
                </Typography>{' '}
                All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction={`row`} alignItems={`center`} flexWrap={`nowrap`} gap={1.25}>
            <Grid item>
              <Button
                type={`button`}
                size={`small`}
                variant={`text`}
                color={'secondary'}
                onClick={openCustomerService}
              >
                {t('customerService')}
              </Button>
            </Grid>
            <Divider orientation='vertical' flexItem sx={{ my: 0.75 }} />
            <Grid item>
              <Button
                type={`button`}
                size={`small`}
                variant={`text`}
                color={'secondary'}
                onClick={openRoadmap}
              >
                {t('roadmap')}
              </Button>
            </Grid>
            <Divider orientation='vertical' flexItem sx={{ my: 0.75 }} />
            <Button
              type='button'
              size='small'
              variant='text'
              color='secondary'
              onClick={handleClick}
              startIcon={<IconLanguage size={16} />}
              endIcon={
                languageAnchorEl != null ? (
                  <IconArrowDropDown size={25} />
                ) : (
                  <IconArrowDropUp size={25} />
                )
              }
            >
              {get(currentLanguage).name}
            </Button>

            <Menu
              anchorEl={languageAnchorEl}
              open={languageAnchorEl != null}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              slotProps={{ paper: { style: { width: '150px' } } }}
            >
              {locales.map((locale) => (
                <MenuItem
                  key={locale.value}
                  onClick={() => onChangeLanguage(locale.value)}
                  selected={locale.value.split('-')[0] === currentLanguage}
                >
                  {t(`${locale.label}`)}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};
