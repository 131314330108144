import React from 'react';
import { PDCThemeProvider, PDCStyledEngineProvider } from '@mzc-pdc/ui';
import theme from './theme';

const CpThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <PDCStyledEngineProvider>
    <PDCThemeProvider theme={theme}>{children}</PDCThemeProvider>
  </PDCStyledEngineProvider>
);

export default CpThemeProvider;
