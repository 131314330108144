import React from 'react';
import { MenuItem } from '@mzc-pdc/ui';

interface PopoverMenuItemProps {
  onClick?: <T>(params: T) => void;
  children: React.ReactNode;
}

export const PopoverMenuItem = (props: PopoverMenuItemProps) => (
  <MenuItem onClick={props.onClick}>{props.children}</MenuItem>
);
