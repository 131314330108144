import React, { ReactNode } from 'react';
import { Button } from '@mzc-pdc/ui';

export const UploadButton = (props: {
  accept: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  children: ReactNode;
}) => (
  <Button variant={'outlined'} color={'primary'} size={'medium'} component={'label'}>
    {props.children}
    <input hidden accept={props.accept} type='file' onChange={props.onChange} />
  </Button>
);
