import React from 'react';
import { Box, CircularProgress as CpdCircularProgress } from '@mzc-pdc/ui';

export const CircularLoader = () => (
  <Box
    sx={{
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
      height: `100%`,
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(0, -50%)'
    }}
    className={'loading-block'}
  >
    <CpdCircularProgress color={'secondary'} size={40} thickness={4} />
  </Box>
);
