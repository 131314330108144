import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { config } from '@config/config';

const TOKEN_COOKIE_NAME = 'token';

export const useAuthorizationCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  return useMemo(
    () => ({
      set: (value: string) =>
        setCookie(TOKEN_COOKIE_NAME, value, {
          expires: new Date(Date.now() + 60 * 60 * 24 * 1000), // 1 day
          path: '/',
          domain: config.domain
        }),
      remove: () => {
        removeCookie(TOKEN_COOKIE_NAME, { path: '/', domain: config.domain });
      },
      get: () => cookies[TOKEN_COOKIE_NAME]
    }),
    [cookies, setCookie]
  );
};
