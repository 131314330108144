import React from 'react';
import { theme } from '../../theme';
import { Avatar as PdcAvatar } from '@mzc-pdc/ui';

interface AvatarProps {
  color?: string;
  size?: number;
  src?: string;
  alt?: string;
  children?: React.ReactNode;
}

export const Avatar = (props: AvatarProps) => (
  <PdcAvatar
    alt={props.alt}
    sx={{
      width: props.size ?? 20,
      height: props.size ?? 20,
      src: props.src,
      mt: 0.25,
      fontSize: theme.typography.body4.fontSize,
      fontWeight: `bold`,
      color: props.color ?? theme.palette.common.white
      // bgcolor: props.bgColor
    }}
  >
    {props.children}
  </PdcAvatar>
);
