import React, { useEffect } from 'react';
import { StepProps } from './infrastructures/StepProps';
import {
  Box,
  Step as StyledStep,
  StepContent,
  StepFooter,
  StepLabel,
  StepSummary,
  Summary
} from './infrastructures/styled';
import { theme } from '../../theme';
import { RequiredLabel } from '../containers/RequiredLabel';
import { ValidConditionContainer } from '../containers/ValidConditionContainer';
import { Button, Grid, Typography } from '@mzc-pdc/ui';
import { IconChevronDown } from '@mzc-cloudplex/icons';
import { useTranslation } from 'react-i18next';
import { StepperContext } from './infrastructures/StepperContext';

export const Step = (props: StepProps) => {
  const { t } = useTranslation();
  const stepperContext = React.useContext(StepperContext);
  if (!stepperContext) throw new Error('Stepper must be used within a StepperProvider');

  const { activeStep, setActiveStep, controller, childrenCount } = stepperContext;
  const active = activeStep === props.index;
  const completed = activeStep > (props.index as number);
  const last = props.index === childrenCount - 1;

  const { initStep, prevFn, nextFn } = controller.getActions(props.name);
  const handleNext = async () => {
    if ((nextFn == null || (await nextFn?.())) && activeStep < childrenCount - 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePrev = () => {
    if (activeStep > 0) {
      prevFn?.();
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    if (active) initStep?.();
  }, [active]);

  return (
    <StyledStep index={props.index} active={active} completed={completed}>
      <StepLabel>
        <Box display={'flex'} flexDirection={'column'}>
          <Grid style={{ display: 'inline-block' }}>
            <RequiredLabel>
              <Typography>{props.label}</Typography>
            </RequiredLabel>
          </Grid>
          {active ? (
            <Typography variant={'body3'} color={theme.palette.warning.main}>
              {t('common::fillOut')}
            </Typography>
          ) : completed ? (
            <Typography variant={'body3'} color={theme.palette.success.main}>
              {t('common::inputComplete')}
            </Typography>
          ) : (
            <Typography variant={'body3'} color={'text.secondary'}>
              {props?.description}
            </Typography>
          )}
        </Box>
      </StepLabel>
      {completed && props.summary != null && (
        <StepSummary>
          <Summary>
            {props.summary.map((item, index) => (
              <Grid container gap={5} py={1} key={index}>
                <Grid item minWidth={120}>
                  <RequiredLabel>{item?.label ? item.label : ''}</RequiredLabel>
                </Grid>
                <Grid item>{item.value}</Grid>
              </Grid>
            ))}
          </Summary>
        </StepSummary>
      )}
      <StepContent>
        <Box px={2.5} py={2}>
          {props.children}
        </Box>
        <StepFooter>
          <Grid container gap={0.5}>
            <ValidConditionContainer condition={props.index != null && props.index > 0}>
              <Grid item>
                <Button
                  size={'small'}
                  variant={'outlined'}
                  loading={props.loading}
                  color={'greySecondary'}
                  hidden={props.index === 0}
                  onClick={handlePrev}
                >
                  {t('prevStep')}
                </Button>
              </Grid>
            </ValidConditionContainer>
            <Grid item>
              <Button
                size={'small'}
                variant={'contained'}
                color={'primary'}
                loading={props.loading}
                disabled={props.nextDisabled || props.loading}
                endIcon={last ? undefined : <IconChevronDown size={16} />}
                onClick={handleNext}
              >
                {props.nextLabel ?? t('nextStep')}
              </Button>
            </Grid>
          </Grid>
        </StepFooter>
      </StepContent>
    </StyledStep>
  );
};
