import { Button } from '@mzc-pdc/ui';
import React from 'react';
import { DialogButtonProps } from './infrastructures/DialogButtonProps';

export const RejectDialogButton = (props: DialogButtonProps) => {
  return (
    <Button disabled={props.disabled} onClick={props.onClick} color={'secondary'} variant={'text'}>
      {props.children}
    </Button>
  );
};
