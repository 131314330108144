import React from 'react';
import { Footer as CPFooter } from '@packages/cp-ui';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { i18n } = useTranslation('common', { keyPrefix: 'footer' });
  return (
    <CPFooter
      onLocaleChange={async (value) => {
        await i18n.changeLanguage(value);
      }}
    />
  );
};

export default Footer;
