import React from 'react';
import { Typography as PdcTypography } from '@mzc-pdc/ui';
import { TypographyProps } from './infrastructures/TypographyProps';

export const TypographySubtitle2 = (props: TypographyProps) => (
  <PdcTypography
    variant={props.bold ? 'subtitle2_500' : 'subtitle2'}
    align={props.centered ? 'center' : undefined}
  >
    {props.children}
  </PdcTypography>
);
