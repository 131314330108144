import enCdn from '@locales/en-us/cdn.json';
import koCdn from '@locales/ko-kr/cdn.json';

import enCommon from '@locales/en-us/common.json';
import koCommon from '@locales/ko-kr/common.json';

import enCredential from '@locales/en-us/credential.json';
import koCredential from '@locales/ko-kr/credential.json';

import enDashboard from '@locales/en-us/dashboard.json';
import koDashboard from '@locales/ko-kr/dashboard.json';

import enDns from '@locales/en-us/dns.json';
import koDns from '@locales/ko-kr/dns.json';

import enEndpoint from '@locales/en-us/endpoint.json';
import koEndpoint from '@locales/ko-kr/endpoint.json';

import enJobs from '@locales/en-us/jobs.json';
import koJobs from '@locales/ko-kr/jobs.json';

import enMetrics from '@locales/en-us/metrics.json';
import koMetrics from '@locales/ko-kr/metrics.json';

import enNotificationReceiver from '@locales/en-us/notificationReceiver.json';
import koNotificationReceiver from '@locales/ko-kr/notificationReceiver.json';

import enProject from '@locales/en-us/project.json';
import koProject from '@locales/ko-kr/project.json';

import enRoutingPolicy from '@locales/en-us/routingPolicy.json';
import koRoutingPolicy from '@locales/ko-kr/routingPolicy.json';

import enStorage from '@locales/en-us/storage.json';
import koStorage from '@locales/ko-kr/storage.json';

import enUser from '@locales/en-us/user.json';
import koUser from '@locales/ko-kr/user.json';

import enAuditLog from '@locales/en-us/auditLog.json';
import koAuditLog from '@locales/ko-kr/auditLog.json';

const locales = {
  'en-us': {
    common: enCommon,
    cdn: enCdn,
    credential: enCredential,
    dashboard: enDashboard,
    dns: enDns,
    endpoint: enEndpoint,
    jobs: enJobs,
    metrics: enMetrics,
    notificationReceiver: enNotificationReceiver,
    project: enProject,
    routingPolicy: enRoutingPolicy,
    storage: enStorage,
    auditLog: enAuditLog,
    user: enUser
  },
  'ko-kr': {
    common: koCommon,
    cdn: koCdn,
    credential: koCredential,
    dashboard: koDashboard,
    dns: koDns,
    endpoint: koEndpoint,
    jobs: koJobs,
    metrics: koMetrics,
    notificationReceiver: koNotificationReceiver,
    project: koProject,
    routingPolicy: koRoutingPolicy,
    storage: koStorage,
    auditLog: koAuditLog,
    user: koUser
  }
};

export default locales;
