import React, { ReactNode } from 'react';
import { Box, Grid, Typography, Divider, theme } from '@mzc-pdc/ui';

interface PageProps {
  title: string | ReactNode;
  prev?: ReactNode;
  breadcrumbs?: ReactNode;
  actions?: ReactNode;
  children: ReactNode;
  panelExtended?: boolean;
}

export const Page = ({ title, prev, breadcrumbs, actions, children, panelExtended }: PageProps) => (
  <main
    style={{
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginRight: 0,
      ...(panelExtended && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.enteringScreen
        }),
        marginRight: 400
      })
    }}
  >
    <Grid container flexDirection={'column'} justifyContent={'space-between'}>
      {breadcrumbs != null && <Grid item>{breadcrumbs}</Grid>}
      <Grid item>
        <Grid
          container
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          minHeight={`40px`}
          wrap={'nowrap'}
          mt={1}
          mb={2}
        >
          <Grid item display={'inline-flex'} alignItems={'center'} gap={1} style={{ flex: 1 }}>
            {prev && <>{prev}</>}
            <Box className={'title'} sx={{ overflow: 'hidden', wordBreak: 'break-all' }}>
              <Typography variant={'h3'} component={'strong'}>
                {title}
              </Typography>
            </Box>
          </Grid>
          {actions && <Grid item>{actions}</Grid>}
        </Grid>
      </Grid>
      <Divider
        orientation={'horizontal'}
        sx={{ width: `100%`, borderColor: `${theme.palette.secondary[800]}` }}
      />
      <Grid item>{children}</Grid>
    </Grid>
  </main>
);
