import React, { ReactNode } from 'react';
import { Alert as PdcAlert } from '@mzc-pdc/ui';
import { IconErrorCircle } from '@mzc-cloudplex/icons';

export const ErrorAlert = (props: { children: ReactNode }) => (
  <PdcAlert
    color={'error'}
    severity={'error'}
    icon={<IconErrorCircle fontSize='small' height={20} />}
  >
    {props.children}
  </PdcAlert>
);
