import React, { useCallback, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mzc-pdc/ui';
import { theme } from '../../theme';
import TextAreaProps from './infrastructures/TextAreaProps';

export const TextArea = (props: TextAreaProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      textarea: { '~ div': { display: `none` } }
    }}
  >
    <TextField
      fullWidth
      type={'text'}
      inputProps={{ maxLength: props?.maxCharacters ?? 2000 }}
      multiline={true}
      value={props.value ?? ''}
      placeholder={props?.placeholder ?? ''}
      disabled={props?.disabled}
      onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
        props.onChange(event.target.value)
      }
      error={props?.errorMessage != null}
      helperText={props?.errorMessage}
    />
  </Box>
);
