import { Box, Typography } from '@mzc-pdc/ui';
import React from 'react';
import { Link, LinkProps } from '../links/Link';
import { theme } from '../../theme';

export const WithIdRow = (props: { id: string; children: React.ReactNode } & LinkProps) => (
  <Link to={props.to} target={props.target}>
    <Box display={'flex'} flexDirection={'row'} gap={0.5}>
      <Typography
        component={'span'}
        variant={'subtitle2'}
        overflow={'hidden'}
        noWrap
        textOverflow={'ellipsis'}
      >
        {props.children}
      </Typography>
      <Typography component={'span'} variant={'subtitle2'} color={theme.palette.text.secondary}>
        ({props.id})
      </Typography>
    </Box>
  </Link>
);
