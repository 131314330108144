import React from 'react';
import { Button } from '@mzc-pdc/ui';
import { IconLogoMzc } from '@mzc-cloudplex/icons';
import { useTranslation } from 'react-i18next';

export const SignInButton = (props: { signInLink: string }) => {
  const { t } = useTranslation();
  return (
    <Button
      size={'medium'}
      variant={'contained'}
      fullWidth
      color={'grey'}
      href={props.signInLink}
      startIcon={<IconLogoMzc size={24} htmlColor={'#fff'} style={{ marginRight: `16px` }} />}
    >
      <strong>{t('popsLogin')}</strong>
    </Button>
  );
};
