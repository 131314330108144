import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Tooltip as PdcTooltip, Typography as PdcTypography } from '@mzc-pdc/ui';

export const Ellipsis = (props: {
  children: ReactElement<typeof PdcTypography>;
  maxWidth?: number;
}) => {
  const textElementRef = useRef<HTMLSpanElement>(null);
  const [isOverflowed, setOverflowed] = useState(false);

  const checkOverflow = () => {
    if (textElementRef.current) {
      setOverflowed(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <PdcTooltip title={props.children} disableHoverListener={!isOverflowed}>
      <span
        ref={textElementRef}
        style={{
          maxWidth: props.maxWidth,
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {props.children}
      </span>
    </PdcTooltip>
  );
};
