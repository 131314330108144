import React from 'react';
import { IconButton } from '@mzc-pdc/ui';
import { IconBack } from '@mzc-cloudplex/icons';
import { IconButtonProps } from './infrastructures/IconButtonProps';
import { theme } from '../../theme';

export const PrevIconButton = (props: IconButtonProps) =>
  props.colored ? (
    <IconButton
      circled
      size={`small`}
      onClick={props.onClick}
      sx={{
        backgroundColor: `${theme.palette.primary[300]}`,
        ':hover': {}
      }}
    >
      <IconBack size={16} htmlColor={theme.palette.primary.contrastText} />
    </IconButton>
  ) : (
    <IconButton circled size={`large`} onClick={props.onClick}>
      <IconBack size={24} htmlColor={theme.palette.secondary[800]} />
    </IconButton>
  );
