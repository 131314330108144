import React from 'react';
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Select as PdcSelect,
  Typography
} from '@mzc-pdc/ui';
import { Validation } from '../../infrastructures/validation';
import { IconCancelCircle } from '@mzc-cloudplex/icons';
import { theme } from '../../theme';

interface SelectProps<T extends string | readonly string[] | number | undefined> {
  label?: string;
  value: T;
  onClear?: () => void;
  onChange: (value: T) => void;
  children: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  required?: Validation['required'];
  multiple?: boolean;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  maxMenuHeight?: number;
  renderValue?: (value: T) => React.ReactNode;
  errorMessage?: string;
}

export const Select = <T extends string | readonly string[] | number | undefined>({
  fullWidth = true,
  ...props
}: SelectProps<T>) => {
  return (
    <FormControl fullWidth={fullWidth} error={props.errorMessage != null}>
      <InputLabel required={props.required}>{props.label}</InputLabel>
      <PdcSelect
        style={props.style}
        label={props.label}
        placeholder={props.placeholder ?? ''}
        disabled={props.disabled || props.loading}
        MenuProps={{
          slotProps: {
            paper: {
              sx: {
                maxHeight: props.maxMenuHeight ?? 256
              }
            }
          }
        }}
        endAdornment={
          props.loading ? (
            <CircularProgress size={20} color={'secondary'} />
          ) : props.onClear != null ? (
            <IconButton onClick={props.onClear} sx={{ mr: 2 }}>
              <IconCancelCircle size={16} htmlColor={theme.palette.greySecondary['500']} />
            </IconButton>
          ) : undefined
        }
        multiple={props.multiple}
        fullWidth={fullWidth}
        value={props.value}
        onChange={(event) => {
          const value = event.target.value as T;
          props.onChange(value);
        }}
        displayEmpty={props.label == null && props.placeholder != null}
        renderValue={
          props?.renderValue != null
            ? (value: unknown) => {
                if (props.label == null && props.placeholder != null) {
                  const list = value as string[];
                  if (value == null || list?.length === 0) {
                    return (
                      <Typography color={theme.palette.greySecondary[300]}>
                        {props.placeholder}
                      </Typography>
                    );
                  }
                }

                return props?.renderValue?.(value as T);
              }
            : undefined
        }
      >
        {props.children}
      </PdcSelect>
      {props.errorMessage != null && <FormHelperText>{props.errorMessage}</FormHelperText>}
    </FormControl>
  );
};
