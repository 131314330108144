import {
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryResult
} from '@tanstack/react-query';
import { QueryOptions } from '@hook/useCases/infrastructures/queryOptions';
import { ExceptionHandler } from '@hook/exceptionHandlers/exceptionHandler';

export const useQueryBase = <TQueryFnData, TQueryKey extends QueryKey>(
  key: TQueryKey,
  query: QueryFunction<TQueryFnData, TQueryKey, never>,
  options?: QueryOptions,
  handleException?: ExceptionHandler
): UseQueryResult<TQueryFnData, unknown> => {
  const tryCatchQueryFn = async (context: QueryFunctionContext<TQueryKey, never>) => {
    try {
      return await query(context);
    } catch (error) {
      const shouldBeThrown = handleException?.(error);
      if (shouldBeThrown) throw error;
    }
  };

  return useQuery({
    queryKey: key,
    queryFn: tryCatchQueryFn,
    enabled: options?.enabled ?? true,
    throwOnError: options?.throwOnError ?? false,
    gcTime: options?.cacheTime ?? 5 * 60 * 1000
  });
};
