import React from 'react';
import { Grid as PdcGrid } from '@mzc-pdc/ui';
import { ChipProps } from './infrastructures/ChipProps';
import { Chip } from './Chip';

export const Chips = (props: { chips: ChipProps[] }) => (
  <PdcGrid container gap={1}>
    {props.chips.map((chip, index) => (
      <PdcGrid item key={index}>
        <Chip {...chip} />
      </PdcGrid>
    ))}
  </PdcGrid>
);
