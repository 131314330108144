import { useCallback, useMemo } from 'react';
import { DomainId } from '../infrastructures/domainId';

export const useThumbnailColorProvider = () => {
  const thumbnailColors = useMemo(
    () => [
      '#ef4b4b',
      '#405fff',
      '#5d8fff',
      '#5f62e5',
      '#8660d9',
      '#7182a6',
      '#e86894',
      '#3f485a',
      '#5a4a3f',
      '#3f585a',
      '#ffaa31',
      '#009ea0'
    ],
    []
  );
  return {
    get: useCallback(
      (domainId: DomainId<string>) =>
        thumbnailColors[
          domainId.id.split('').reduce((acc, cur) => acc + cur.charCodeAt(0), 0) %
            thumbnailColors.length
        ],
      []
    )
  };
};
