import React, { lazy, ReactNode, Suspense, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import useRouting, { RoutingInfo } from '@hook/useRouting';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CircularLoader, CpThemeProvider, CssBaseline } from '@packages/cp-ui';
import AuthProtectedRoute from '@ui/components/error/AuthProtectedRoute';
import Layout from '@ui/layouts/layouts/Layout';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InvalidUser = lazy(() => import('@ui/components/error/fallback/InvalidUser'));
const NotSupportedBrowser = lazy(() => import('@ui/components/error/fallback/NotSupportedBrowser'));

const Welcome = lazy(() => import('@ui/features/Welcome'));

const Login = lazy(() => import('@ui/features/Login'));
const SpaceList = lazy(() => import('@ui/features/SpaceList'));

const Dashboard = lazy(() => import('@ui/features/dashboard/Dashboard'));

const UserList = lazy(() => import('@ui/features/user/UserList'));
const UserDetail = lazy(() => import('@ui/features/user/UserDetail'));

const ProjectList = lazy(() => import('@ui/features/project/ProjectList'));
const ProjectCreateSelection = lazy(() => import('@ui/features/project/ProjectCreateSelection'));
const ProjectCreate = lazy(() => import('@ui/features/project/ProjectCreate'));
const RecordToProjectCreate = lazy(() => import('@ui/features/project/RecordToProjectCreate'));
const ProjectDetail = lazy(() => import('@ui/features/project/ProjectDetail'));

const CredentialList = lazy(() => import('@ui/features/credential/CredentialList'));
const CredentialDetail = lazy(() => import('@ui/features/credential/CredentialDetail'));
const CredentialCreate = lazy(() => import('@ui/features/credential/CredentialCreate'));

const MetricPresetList = lazy(() => import('@ui/features/metricPreset/MetricPresetList'));
const MetricPresetDetail = lazy(() => import('@ui/features/metricPreset/MetricPresetDetail'));
const MetricPresetCreate = lazy(() => import('@ui/features/metricPreset/MetricPresetCreate'));

const NotificationReceiverList = lazy(
  () => import('@ui/features/notificationReceiver/NotificationReceiverList')
);
const NotificationReceiverDetail = lazy(
  () => import('@ui/features/notificationReceiver/NotificationReceiverDetail')
);
const NotificationReceiverCreate = lazy(
  () => import('@ui/features/notificationReceiver/NotificationReceiverCreate')
);

const EndpointList = lazy(() => import('@ui/features/endpoint/EndpointList'));
const DnsList = lazy(() => import('@ui/features/dns/DnsList'));
const CdnList = lazy(() => import('@ui/features/cdn/CdnList'));
const RoutingPolicyList = lazy(() => import('@ui/features/routingPolicy/RoutingPolicyList'));
const StorageList = lazy(() => import('@ui/features/storage/StorageList'));

const CompareJobList = lazy(() => import('@ui/features/job/CompareJobList'));
const PurgeJobList = lazy(() => import('@ui/features/job/PurgeJobList'));
const DnsZoneRecordJobList = lazy(() => import('@ui/features/job/DnsZoneRecordJobList'));

const AuditLogList = lazy(() => import('@ui/features/auditLog/ManagementAuditLogList'));
const AccessAuditLogList = lazy(() => import('@ui/features/auditLog/AccessAuditLogList'));

const isNotSupportedBrowser = () => {
  // todo: 지원하지 않는 브라우저 추가
  return false;
};

const App = () => {
  const routing = useRouting();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false
          },
          mutations: {
            retry: 0
          }
        }
      }),
    []
  );
  if (isNotSupportedBrowser()) routing.navigate(routing.browserNotSupported.getPath());

  const route = (info: RoutingInfo, element: ReactNode) => {
    if (!info.isEnabled) return <></>;
    const authElement = info.isNeededAuthorization ? (
      <AuthProtectedRoute>{element}</AuthProtectedRoute>
    ) : (
      element
    );
    return <Route path={info.rule} element={authElement} />;
  };
  return (
    <QueryClientProvider client={queryClient}>
      <CpThemeProvider>
        <CssBaseline />
        <Layout>
          <Suspense fallback={<CircularLoader />}>
            <Routes>
              {route(routing.login, <Login />)}
              {route(routing.spaces, <SpaceList />)}
              {route(routing.invalidUser, <InvalidUser />)}
              {route(routing.browserNotSupported, <NotSupportedBrowser />)}
              {route(routing.welcome, <Welcome />)}

              {route(routing.home, <Dashboard />)}

              {route(routing.metrics, <MetricPresetList />)}
              {route(routing.metric, <MetricPresetDetail />)}
              {route(routing.createMetrics, <MetricPresetCreate />)}

              {route(routing.notificationReceivers, <NotificationReceiverList />)}
              {route(routing.notificationReceiver, <NotificationReceiverDetail />)}
              {route(routing.createNotificationReceiver, <NotificationReceiverCreate />)}

              {route(routing.users, <UserList />)}
              {route(routing.user, <UserDetail />)}

              {route(routing.projects, <ProjectList />)}
              {route(routing.projectCreateSelection, <ProjectCreateSelection />)}
              {route(routing.projectCreate, <ProjectCreate />)}
              {route(routing.projectCreateFromRecord, <RecordToProjectCreate />)}

              {route(routing.project, <ProjectDetail />)}
              {route(routing.credentials, <CredentialList />)}
              {route(routing.credential, <CredentialDetail />)}
              {route(routing.credentialCreate, <CredentialCreate />)}

              {route(routing.dnses, <DnsList />)}
              {route(routing.cdns, <CdnList />)}
              {route(routing.storages, <StorageList />)}
              {route(routing.routingPolicies, <RoutingPolicyList />)}
              {route(routing.endpoints, <EndpointList />)}

              {route(routing.dnsZoneRecordJobs, <DnsZoneRecordJobList />)}
              {route(routing.purgeJobs, <PurgeJobList />)}
              {route(routing.compareJobs, <CompareJobList />)}

              {route(routing.auditLogs, <AuditLogList />)}
              {route(routing.accessAuditLogs, <AccessAuditLogList />)}
              <Route path={'*'} element={<div>404</div>} />
            </Routes>
          </Suspense>
        </Layout>
        <ToastContainer
          autoClose={3000}
          position={toast.POSITION.TOP_RIGHT}
          hideProgressBar={false}
          pauseOnHover={true}
          pauseOnFocusLoss={true}
          rtl={false}
          newestOnTop={true}
          draggable={false}
          theme={'colored'}
        />
      </CpThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
