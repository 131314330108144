import React from 'react';
import {
  AutocompleteRenderGetTagProps,
  Autocomplete as PdcAutocomplete,
  TextField
} from '@mzc-pdc/ui';

interface ChipInputProps<T, Multiple extends boolean | undefined> {
  label?: React.ReactNode;
  minWidth?: number;
  loading?: boolean;
  readonly?: boolean;
  onInputChange?: (value: string) => void;
  placeholder?: string;
  renderTags: (value: T[], getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  value: Multiple extends true ? T[] : T;
  onChange: (values: Multiple extends true ? T[] : T) => void;
  validateChipInput?: (input: T[]) => string | undefined;
}

export const ChipInput = <T,>(props: ChipInputProps<T, true>) => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  return (
    <PdcAutocomplete
      multiple
      freeSolo
      id='tags-filled'
      loading={props.loading}
      sx={{ minWidth: props.minWidth ?? 350 }}
      disableCloseOnSelect={false}
      options={[]}
      value={props.value}
      onChange={(_event, values) => {
        if (props.validateChipInput != null) {
          const errorMessage = props.validateChipInput(values);
          if (errorMessage) {
            setErrorMessage(errorMessage);
            return;
          }
          setErrorMessage(undefined);
        }
        props.onChange(values);
      }}
      renderTags={props.renderTags}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={'outlined'}
          label={props?.label}
          inputProps={{ ...params.inputProps, readOnly: props.readonly }}
          placeholder={props.placeholder ?? ''}
          error={errorMessage != null}
          helperText={errorMessage}
        />
      )}
    />
  );
};
