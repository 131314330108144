import React from 'react';
import { theme } from '../../theme';
import { Avatar as PdcAvatar } from '@mzc-pdc/ui';
import { useThumbnailColorProvider } from '../../hook/useThumbnailColorProvider';
import { DomainId } from '../../infrastructures/domainId';

interface ProjectAvatarProps {
  size?: number;
  src?: string;
  id: DomainId<string>;
  children?: React.ReactNode;
}

export const ProjectAvatar = (props: ProjectAvatarProps) => (
  <PdcAvatar
    sx={{
      width: props.size ?? 20,
      height: props.size ?? 20,
      mt: 0.25,
      fontSize: theme.typography.body4.fontSize,
      fontWeight: `bold`,
      color: theme.palette.common.white,
      backgroundColor: useThumbnailColorProvider().get(props.id)
    }}
  >
    {props.children}
  </PdcAvatar>
);
